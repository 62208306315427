import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import { ProfileUserData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Button, Dropdown ,Drawer} from "antd";
import { UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Avatar, Space } from "antd";
import EnterAddrsss from "./EnterAddrsss";
import CardDrawer from "../pages/CardDrawer";


const UserList = ['U', 'Lucy', 'Tom', 'Edward'];
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
const GapList = [4, 3, 2, 1];

export default function Header({ onTabclick }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [user, setUser] = useState(UserList[0]);
  const [color, setColor] = useState(ColorList[0]);
  const [gap, setGap] = useState(GapList[0]);
  let userId = localStorage.getItem("userId");
  let pincodeId = localStorage.getItem("pincode");
  const [open, setOpen] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);

  const [addressVisible, setAddressVisible] = useState(false);

  const [navbarBg, setNavbarBg] = useState("transparent");
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const {
    loading: getprofileUserLoading,
    loginerror: getprofileUserError,
    getprofile: getUserResponse,
  } = useSelector((state) => state.getprofile);

  const {
    loginmodal: loginmodelres,
  } = useSelector((state) => state.loginmodal);

  const {
    pincodemodal: pincodemodelres,
  } = useSelector((state) => state.pincodemodal);

  useEffect(() => {
    console.log(userId, "userId");
    if (userId !== undefined || userId !== null) {
      dispatch(ProfileUserData(userId));
    }
    // if (getUserResponse) {
    //   const { firstname, lastname } = getUserResponse.User;
    //   message.success(`Welcome ${firstname} ${lastname}`, );
    // }
  }, []);

  useEffect(() => {
    console.log(loginmodelres);
    if (loginmodelres) {
      handleOpenLogin(true)

    }
    if (pincodemodelres) {
      setAddressVisible(true)
    }
  }, [loginmodelres, pincodemodelres])

  const logoutFunction = () => {
    localStorage.removeItem("userId");
    window.location.reload();
    window.location.href = "/";
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const changeColorPosition = 100; // Change color after scrolling down 100 pixels

    if (scrollPosition > changeColorPosition) {
      setNavbarBg("bg-theme-dark  text-white");
    } else {
      setNavbarBg("bg-theme-dark text-white");
    }
  };


    // Handler to collapse the navbar
    const collapseNavbar = () => {
      // Select the collapsible navbar element by ID
      const navbarCollapse = document.getElementById("navbarNav");
      
      // If the collapsible element is not null, collapse it
   // If the collapsible element is not null, collapse it
   if (navbarCollapse) {
    navbarCollapse.classList.remove('show');
  }
      

    };
  const handleOpenLogin = () => {
    setLoginVisible(true);
    setModalVisible(false);
    collapseNavbar()

  };

  const handleCloseLogin = () => {
    setLoginVisible(false);
    setModalVisible(false);
    collapseNavbar()

  };

  const handleOpenModal = () => {
    setModalVisible(true);
    setLoginVisible(false);
    collapseNavbar()


  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setLoginVisible(false);
    collapseNavbar()


  };

  const handleAddressModal = () => {
    setAddressVisible(true);
  };

  const handleCloseAddressModal = () => {
    setAddressVisible(false);
  };


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/account">Account</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a onClick={logoutFunction} href="#">
          Sign out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header>
      <div className="container-fluid ">
        {/* Navbar */}
        <nav
          className={`navbar navbar-expand-lg navbar-light fixed-top bg-theme-dark ${navbarBg} px-md-5 px-3 mobNav`}
        >

          <Link className="navbar-brand d-flex justify-content-start align-items-start" to="/">
            <img
              src={require("../assets/images/logos.webp")}
              className="logo-wid"
              alt="Logo"
            />
            {/* <div className="d-flex mt-2 address-select" style={{ cursor: "pointer" }} onClick={handleAddressModal}>
              <img
                src={require("../assets/images/location-home.png")}
                className="logo-wid-location"
                alt="Logo"
              />
              <div>
                <p className="text-white location-text mb-2">
                  Change the Pin Code

                </p>
                <p className="text-white location-text">
                  {pincodeId ? pincodeId : 'Location'}

                </p>
              </div>

            </div> */}
          </Link>



          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto px-5">

              <li
                className={`nav-item ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/" onClick={() => {
                  onTabclick('myElement5', 'Home');
                  collapseNavbar()
                }}>
                  Home
                </Link>
              </li>
              <li
                className={`nav-item ${location.pathname === "/about" ? "active" : ""
                  }`}
              >
                <Link className="nav-link"
                  to="/"
                  onClick={() => {
                    onTabclick('myElement', 'About Us');
                    collapseNavbar()

                  }} >
                  About Us
                </Link>
              </li>
              <li
                className={`nav-item ${location.pathname === "/bulk" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/"
                  onClick={() => {
                    onTabclick('myElement1', 'UPS');
                    collapseNavbar()

                  }}>
                  Our USP's
                </Link>
              </li>
              <li
                className={`nav-item ${location.pathname === "/menu" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/menu" onClick={() => {
                  onTabclick('myElement2', 'Menu');
                  collapseNavbar()

                }}>
                  Menu
                </Link>
              </li>

              <li
                className={`nav-item ${location.pathname === "/blog" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/blog">
                  Blogs
                </Link>
              </li>
              <li
                className={`nav-item ${location.pathname === "/contact" ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
              {getUserResponse && getUserResponse.User ? (
                <Dropdown overlay={menu} className="mt-1">
                  <a
                    className="ant-dropdown-link link-body-emphasis text-decoration-none"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Avatar
                      style={{
                        backgroundColor: color,
                        verticalAlign: "middle",
                      }}
                      size={30}
                      gap={gap}
                    >
                      {user}
                    </Avatar>

                    <strong className="px-1 text-white">
                      {getUserResponse.User.firstname}
                    </strong>
                  </a>
                </Dropdown>
              ) : (
                <li
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenLogin}
                  className={`nav-item ${location.pathname === "/#" ? "active" : ""
                    }`}
                >
                  <Link className="nav-link" to="#">
                    Sign In
                  </Link>
                </li>
              )}
              {getUserResponse && getUserResponse.User ? null : (
                <ll
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  onClick={handleOpenModal}
                  className="nav-item"
                >
                  <Link to="#" className="button">
                    Sign Up
                  </Link>
                </ll>
              )}
              {getUserResponse && getUserResponse.User && (
                <Link to="/cart" className="text-decoration-none px-1">
                  <ShoppingCartOutlined />
                </Link>
              )}
            </ul>
          </div>
        </nav>
      </div>
      <>
        <EnterAddrsss visible={addressVisible} onClose={handleCloseAddressModal} />
        <LoginModal visible={loginVisible} onClose={handleCloseLogin} />
        <RegisterModal visible={modalVisible} onClose={handleCloseModal} />
      </>
     
      
      
    </header>
  );
}