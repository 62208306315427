import React, { useState, useEffect } from "react";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAddCardProductById,
  DeleteAddCardProductById,
  QtyOrderProductById,
} from "../reducer/thunks";
import constant from "../constant/constant";
import { useNavigate } from "react-router-dom";
import { message, Drawer, Button, Space, Result, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Lottie from 'react-lottie';

import * as animationData from './../constant/images/Animation.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};


const CardDrawer = ({ openDrawer, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [close, setOnclose] = useState(false);

  const description = "This is a description.";

  let userId = localStorage.getItem("userId");

  const {
    GetAddcardUserRes,
    addloading: addloadingLoading,
    error: productListError,
  } = useSelector((state) => state.GetAddcardUserRes);
  const {
    qtyAddcardRes,
    qtycardIdloading: qtyloader,
    error: qtycardIdListError,
  } = useSelector((state) => state.qtyAddcardRes);
  const {
    DeleteAddcardUserRes,
    Deleteloading: DeleteloadingLoading,
    error: DeleteloadingError,
  } = useSelector((state) => state.DeleteAddcardUserRes);
  const {
    GetAddcardRes: NewAddcardres,
    Deleteloading: Loadingres,
    error: DeletelErrorres,
  } = useSelector((state) => state.GetAddcardRes);

  useEffect(() => {
    if (userId !== undefined && userId !== null || qtyAddcardRes || NewAddcardres) {
      dispatch(GetAddCardProductById(userId));
    }
  }, [userId, qtyAddcardRes, DeleteAddcardUserRes, NewAddcardres]); // Add qtyAddcardRes and DeleteAddcardUserRes as dependencies


  useEffect(() => {
    console.log(DeleteAddcardUserRes);
    if (DeleteAddcardUserRes) {

      // Trigger fetching the updated data after successful deletion
      dispatch(GetAddCardProductById(userId));
      message.success(`Successfuly Delete Card`, 5);

    }
  }, [DeleteAddcardUserRes]);

  const handleUpdateQuantity = (prod_id, qtynumber) => {
    console.log(prod_id, qtynumber, "qtynumber");
    let prod_qty = {
      quantity: String(qtynumber),
      savelater: false,
    };
    dispatch(QtyOrderProductById(prod_qty, prod_id));
  };

  const getTotalPrice = () => {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  };
  const handleRemoveItem = (productId) => {
    dispatch(DeleteAddCardProductById(productId));

  };
  const handleCheckout = () => {
    // setOnclose(true);

    if (GetAddcardUserRes.AddCarts.length > 0) {
      if (GetAddcardUserRes.AddCarts.length === 2 || GetAddcardUserRes.quantity === 2 || getTotal() > 123) {
        navigate(`/checkout`);
      } else {
        message.info(`Min order quantity two`, 5);
      }
    } else {
      message.info(`Please select the Biryani`, 5);
    }

  };

  // Define the getSubtotal function
  function getSubtotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  }

  // Define the getTotal function
  function getTotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product?.amount * item.quantity,
        0
      )
    );
  }

  // Dummy content for cart list
  const renderCartList = () => {
    return (
      <div className="row col-md-12">
        {/* Map over AddCarts to create cards for each cart item */}
        {GetAddcardUserRes?.AddCarts?.map((item) => (
          <div className="col-md-12 mt-2" key={item.id}>
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={`${item?.product?.images[0]}`}
                    alt={`Product Image ${item.product?.name}`}
                    style={{ width: "40px", height: "40px" }}
                    className="mr-3"
                  />
                  <div>
                    <span className="product-name">
                      {item.product?.name}
                    </span> <br />
                    {item.Options_id !== "" && (
                      <span className="product-name">
                        {item.Options_id?.name}
                      </span>
                    )}
                  </div>

                </div>
                <div
                  className=""
                  onClick={() => handleRemoveItem(item._id)}
                >
                  <DeleteOutlined />
                </div>
                {/* Quantity and quantity control buttons */}


              </div>
              <div className="d-flex align-items-center justify-content-between p-2">
                <div className="quantity-col d-flex  align-items-center justify-content-center mb-3 bg-orange border rounded">
                  <div
                    className="btn  mr-2 text-white"
                    onClick={() => handleUpdateQuantity(item._id, item.quantity - 1)}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </div>
                  <span className="quantity text-white">{item.quantity}</span>
                  <div
                    className="btn ml-2 text-white"
                    onClick={() => handleUpdateQuantity(item._id, item.quantity + 1)}
                  >
                    +
                  </div>
                </div>
                <p className="product-amount text-center w-25">
                  ₹{item.quantity * item?.product?.amount}
                </p>
              </div>


              <div>
              </div>

            </div>
          </div>
        ))}
      </div>
    );
  };


  const shippingamount = () => {
    const calculatedAmount = parseFloat(getTotal()).toFixed(0);

    if (450 > +calculatedAmount) {
      return `${calculatedAmount} +(DC) 50 =` + " " + Number(+calculatedAmount + 50);
    } else {
      return "Delivery Fee";
    }
  };


  const renderCardtotals = () => {
    return (
      <div className=" col-md-12 price-card position-absolute bottom-0 start-0">

        <div className="cart-list cart-totals m-4">



          {/* Proceed to Checkout button */}
          <div className="d-flex align-items-center justify-content-between">
            <p className="mt-2">
              <span>Subtotal :</span>
              <span>₹{getSubtotal()}</span>
            </p>
            <button className="btn button" onClick={handleCheckout}>
              Checkout
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {openDrawer && <Drawer
        title="Your Cart"
        width={400}
        onClose={onClose}
        open={openDrawer}
        className="position-relative"

      >
        {renderCartList()}
        {renderCardtotals()}


      </Drawer>}

      
      {/* <Modal
        visible={openDrawer}
        onCancel={() => {
          onClose(false);
        }}
        height="300px"
        width="770px"
        style={{ marginTop: "3%" }}
        footer={null}
      >

        <h4 className="text-center font-weight-bold head-control mt-5">Our delivery service is launching soon!
          Stay tuned for updates and get ready to enjoy the convenience of having your favorite Biryani delivered right to your doorstep!!</h4>

        <Result
          title=""
          icon={<Lottie options={defaultOptions}

            height={250}
            width={250}
          />}


        />
      </Modal> */}
    </>
  );
};

export default CardDrawer;
