import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message, Alert } from 'antd';
import { useDispatch } from "react-redux";

const availablelocation = [
    { pincode: "560102", Address: "Hsr" },
    { pincode: "560034", Address: "koramangala" },
    { pincode: "560038", Address: "Indiranagar" },
    { pincode: "0", Address: "Other" }

]
const EnterAddrsss = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [selectenable, setSelectEnable] = useState(true);

    let pincodeId = localStorage.getItem("pincode");


    const handleAddAddress = (values) => {
        const { pinCode } = values;
        const location = availablelocation.find(loc => loc.pincode === pinCode);
        if (location) {
            if (location.pincode === "0") {
                setSelectEnable(false)
            } else {
                // Pin code found, show success message
                localStorage.setItem("pincode", location.pincode);
                message.success(`Pin code ${pinCode} is available at ${location.Address}`);
                onClose()
            }

        } else {
            // Pin code not found, show error message
            message.error(`We're sorry, we don't have a shop at this pin code`, 5);
        }
    };

    useEffect(() => {
        // Clear error message when modal is closed
        if (!visible) {
            setErrorMessage("");
        }
    }, [visible]);

    const handleClear = () => {
        form.resetFields();
    };

    return (
        <>
            <Modal
                visible={visible}
                onCancel={onClose}
                width="380px"
                footer={null}
                style={{ marginTop: "14%" }}
            >
                <h4 className="text-center font-weight-bold head-control mt-4 mb-4">
                    {pincodeId ? "Change the Pin Code" : "Enter the Pin Code"}
                </h4>

                <Form form={form} onFinish={handleAddAddress}>
                    <div className='col-md-12 row d-flex align-items-center'>
                        <Form.Item
                            name="pinCode"
                            className='col-md-12'
                            rules={[{ required: true, message: 'Please enter pin code' }]}
                        >
                            <Input className="form-control text-input" placeholder="Pin Code" />
                        </Form.Item>
                        {selectenable && <div className='col-md-12'>
                            {availablelocation.map((i) => (
                                <Button onClick={() => {
                                    handleAddAddress({ pinCode: i.pincode })
                                }}>
                                    {i.Address}
                                </Button>
                            ))}
                        </div>}


                        <Form.Item>
                            <div style={{ textAlign: 'center' }} className='col-md-12'>
                                <Button type="primary" className="btn button w-75 h-25 mt-4" htmlType="submit">
                                    Search the Pin code
                                </Button>
                            </div>
                        </Form.Item>
                    </div>
                </Form>
                {errorMessage && (
                    <div style={{ textAlign: 'center', marginTop: '16px' }}>
                        <Alert message={errorMessage} type="error" showIcon />
                    </div>
                )}
            </Modal>
        </>
    );
};



export default EnterAddrsss;
