import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import Bulk from "./pages/Bulk";
import BlogDetail from './pages/Blogs';
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import Payment from "./pages/Payment";
import Account from "./pages/Account";


import Blog2 from './pages/blog2';
import Blog3 from './pages/blog3';
import Policy from "./pages/policy";
import Terms from "./pages/terms";
import Refund from "./pages/refund-and-policy";
const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/bulk" element={<Bulk />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/blog/:slug/blog2" element={<Blog2 />} />
          <Route path="/blog/:slug/blog3" element={<Blog3 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Payment />} />
          <Route path="/account" element={<Account />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/refund" element={<Refund />} />



        </Routes>
      </Router>
    </div>
  );
};

export default App;
