import React from "react";
import { Button, Container } from "react-bootstrap";
import constant from "../constant/constant";
import Carousel from 'react-bootstrap/Carousel';

import { TypeAnimation } from "react-type-animation";

class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
    // Bind methods
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleAnimationEnd() {
    // Move to the next slide when TypeAnimation completes
    this.setState((prevState) => {
      if (prevState.index === 3) {
        return { index: 0 };
      } else {
        return { index: prevState.index + 1 };
      }
    });
  }

  handleSelect(selectedIndex) {
    console.log(selectedIndex);
    // Update the index state based on the selected index
    if (selectedIndex === 3) {
      this.setState((prevState) => ({ index: prevState.index + 1 }));
    } else {
      this.setState({ index: selectedIndex });
    }
  }

  render() {
    return (
      <section className="mt-5 mt-lg-0">
        <Carousel className="cor-item" interval={3000} indicators={false}>
          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/banner1.webp")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mob-banner-1.webp")}
              />
              <img
                src={require("../constant/images/banner1.webp")}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>

            <div className="carousel-caption carousel-content mt-80">
              <TypeAnimation
                sequence={[
                  `The Hunger Pang Biryani`,
                  2000,
                  `The No Nonsense Biryani`,
                  2000,
                  `The Happy Wala Biryani`,
                  2000,
                  `The Biryani Of Mysore`,
                  2000,
                  `The Biryani of Arcot Nawab’s`,
                  2000,
                  `The Biryani of Bangalore`,
                  2000,
                  // this.handleAnimationEnd 
                ]}
                repeat={Infinity}
                wrapper="h3"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/box-image1.png")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mbanner-box1.png")}
              />
              <img
                src={require("../constant/images/box-image1.png")}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/box-image2.png")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mbanner-box2.png")}
              />
              <img
                src={require("../constant/images/box-image2.png")}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/box-image3.png")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mbanner-box3.png")}
              />
              <img
                src={require("../constant/images/box-image3.png")}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={require("../constant/images/box-image4.png")}
              />
              <source
                media="(max-width: 767px)"
                srcSet={require("../constant/images/mbanner-box4.png")}
              />
              <img
                src={require("../constant/images/box-image4.png")}
                alt="Flowers"
                style={{ width: "100%" }}
              />
            </picture>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  }
}

export default Banners;
