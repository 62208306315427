import React,{useEffect,useRef} from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import { fetchBannerData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constant from "../constant/constant";
import { TypeAnimation } from "react-type-animation";
import Carousel from 'react-bootstrap/Carousel';
import Banners from "../componets/Banners";

export default function Blog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myElementRef = useRef(null); // Define a ref using useRef hook

  // Destructuring with different names to avoid conflict
  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);

  useEffect(() => {
    dispatch(fetchBannerData());
    const myElement = document.getElementById("myElement2");
    if (myElement) {
      // Do something with the element, for example, scroll to it
      myElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <Header />
    
      <Banners/>
      <section className="py-5" id="myElement2" ref={myElementRef}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Blogs</h2>
            </div>
            {data &&
              data.Faqs &&
              data.Faqs.slice(0, 3).map((item) => (
                <div className="col-md-4 text-center mb-5 mb-md-0">
                  <div className="blog-box mb-4">
                    <img
                      src={`${item.imageUrl}`}
                      className="img-fluid mb-3"
                    />
                    <div className="p-2">
                    <h3 className="font-size-blogs blogspace">{item.name}</h3>
                    <p className="pb-3 blogspace mt-3">
                      {item.description}{" "}
                    </p>
                      </div>
                   
                  </div>
                  <Link to={`/blog/${item._id}`} className="readmore-button">
                    {" "}
                    Read more{" "}
                  </Link>
                </div>
              ))}
          
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
