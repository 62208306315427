import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { Link } from "react-router-dom";
import {
  fetchBannerData,
  fetchProductData,
  PincodeModalOpen,
  AddCardProductById,
  loginModalOpen,
} from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constant from "../constant/constant";
import { TypeAnimation } from "react-type-animation";
import { message, Modal } from "antd";
import SplashScreen from "../componets/SplashScreen";
import Banners from "../componets/Banners";
import { UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import CardDrawer from "./CardDrawer";
import Carousel from "react-bootstrap/Carousel";
import Zomoto from "../constant/images/Asset1.svg"
import Swiggy from "../constant/images/Asset 2.svg"


const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
export default function Menu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [options, setOptions] = useState(false);
  const [optionslist, setOptionsArray] = useState([]);
  const [productid, setProductName] = useState("");

  // A state to track the expanded/collapsed view for descriptions
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const myElementRef = useRef(null); // Define a ref using useRef hook

  const [selectindex, setSelectIndex] = useState(0);

  let pincodeId = localStorage.getItem("pincode");
  let userId = localStorage.getItem("userId");

  // Destructuring with different names to avoid conflict
  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);

  const {
    productlist,
    loading: productListLoading,
    error: productListError,
  } = useSelector((state) => state.productlist);
  console.log(
    productlist && productlist.userProducts && productlist.userProducts,
    "product"
  );

  document.title = "Local Heros";
  document.getElementsByTagName("META")[2].content = "Local Heros";

  const handleNavigation = (productId) => {
    console.log(pincodeId);
    if (pincodeId !== null) {
      navigate(`/product/${productId}`);
    } else {
      dispatch(PincodeModalOpen(true));
    }
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  const HandleDrawer = async (id, optionsID) => {
    if (userId) {
      let addcarditem = {
        userId: userId,
        productId: id,
        quantity: "1",
        Options_item: optionsID
      };
      await dispatch(AddCardProductById(addcarditem));
      setDrawer(!drawer);
    } else {
      message.info(`Pls select the login`, 5);
      await dispatch(loginModalOpen(true));
    }
  };
  useEffect(() => {
    dispatch(fetchBannerData());
    dispatch(fetchProductData());
  }, []);

  useEffect(() => {
    if (
      productlist?.userProducts &&
      data?.Categorys &&
      data?.Categorys.length > 0
    ) {
      changeMenu(data.Categorys[0]._id, 0); // Assuming you want to pass the ID of the first category
      const myElement = document.getElementById("myElement2");
      if (myElement) {
        // Do something with the element, for example, scroll to it
        myElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data?.Categorys]); // Only trigger when data.Categorys changes
  const changeMenu = (id, index) => {
    setSelectIndex(index);
    // Filter the user products based on the provided id
    const filteredProducts = productlist.userProducts.filter((product) => {
      return product.category === id;
    });
    const filteredProductOptions = productlist.userProducts.filter((product) => {
      return product.category === id;
    });


    console.log("Filtered Products:", filteredProducts);

    // Update the state with the filtered products
    setProduct([...filteredProducts]); // Using spread operator to create a new array
    if (index === 0) {
      setOptionsArray([...filteredProductOptions]); // Using spread operator to create a new array

    }

    console.log("Updated Product State:", product);

    // You can also directly return the filtered products if needed
    return filteredProducts;
  };
  const getProductPath = (productId) => {
    if (pincodeId && userId) {
      return `/product/${productId}`;
    } else {
      if (pincodeId) {
        message.info(`Pls select the login`, 5);
      } else if (userId) {
        message.info(`login in once can you select the product`, 5);
      }
    }
  };
  const renderDynamicValues = (dataFromBackend) => {
    // Split the string by both commas and '\n' to get an array of lines
    const lines = dataFromBackend.split(/[+\n]/);

    // Filter out any empty or whitespace-only lines
    const filteredLines = lines.filter((line) => line.trim() !== "");

    // Map each line to a <li> tag wrapped in a <ul>
    const listItems = filteredLines.map((line, index) => (
      <li key={index}>{line.trim()}</li>
    ));

    // Return the list items wrapped in a <ul>
    return (
      <ul className="text-black text-start fs-6 list-style">{listItems}</ul>
    );
  };

  const renderDynamicValue = (dataFromBackend) => {
    // Split the string by both commas and '\n' to get an array of lines
    const lines = dataFromBackend.split(/[.\n]/);

    // Filter out any empty or whitespace-only lines
    const filteredLines = lines.filter((line) => line.trim() !== "");

    // Map each line to a <li> tag wrapped in a <ul>
    const listItems = filteredLines.map((line, index) => (
      <li key={index}>{line.trim()}</li>
    ));

    // Return the list items wrapped in a <ul>
    return (
      <ul className="text-black text-start fs-6 list-style">{listItems}</ul>
    );
  };

  const handleClick = (id, menuItem) => {
    // Check if the menu item matches the desired value
    // Accessing the element by its ID and performing actions
    const myElement = document.getElementById("myElement2");
    if (myElement) {
      // Do something with the element, for example, scroll to it
      myElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDescriptionToggle = (id) => {
    // Toggle the state of the expanded description for the given id
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // const onHandleOptions = (item) => {
  //   if (item.options) {
  //     setOptions(true);
  //     setProductName(item._id)
  //   } else {
  //     HandleDrawer(item._id,"");
  //     setProductName("")

  //   }
  // };
  const onHandleOptions = (item) => {
    setOptions(true);
  };

  const selectItem = (item, prod_id) => {
    // HandleDrawer(item, prod_id);
    // setOptions(false);
    setOptions(false);
    window.open(prod_id, '_blank'); // '_blank' ensures the URL opens in a new tab
  }

  if (!data) {
    return <SplashScreen />;
  }
  return (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>
      <div className="zindex-card">
        <CardDrawer
          openDrawer={drawer}
          onClose={() => {
            setDrawer(!drawer);
          }}
        />
        <Header onTabclick={handleClick} />
      </div>

      <Banners />
      <section className="py-5" id="" ref={myElementRef}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className="theme-color">Menu</h2>

            </div>
          </div>
          {/* <div className="col-md-12 text-start mb-4">
            <h4 className="text-black">Menu</h4>
          </div> */}
          <div>
            <ul
              className="nav nav-pills mb-3 categoryCard_wrapper col-md-12"
              id="pills-tab"
              role="tablist "
            >
              {data &&
                data.Categorys &&
                data.Categorys.map((item, index) => (
                  <li key={index} className="nav-item " role="presentation">
                    <button
                      className={`nav-link  categoryCard col-md-3 ${index === 0 ? "active" : ""
                        }`}
                      id={`pills-${item._id.toLowerCase()}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-${item._id.toLowerCase()}`}
                      onClick={() => {
                        changeMenu(item._id, index);
                      }}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${item._id.toLowerCase()}`}
                      aria-selected={index === 0 ? "true" : "false"}
                    >
                      <img
                        src={`${item.imageUrl}`}
                        className="img-fluid rounded-circle image-card"
                        width={"120px"}
                        height={"45px"}
                        alt="menu"
                      />
                      <p>{item.name}</p>
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          <div className="menu-box-bg">
            <div className="row justify-content-center">
              <div className="col-md-12 mb-5 text-center">
                <div class="tab-content">
                  <div className="row d-flex justify-content-center">
                    {data &&
                      product &&
                      product.map((item) => (
                        <React.Fragment>
                          <div className="col-md-4 mb-3 ">
                            <div className="menu-box rounded border border-dark">
                              {item?.images.length > 1 ? (
                                <Carousel
                                  afterChange={onChange}
                                  interval={3000}
                                  indicators={true}
                                  controls={false}
                                  wrap={true}
                                >
                                  {item.images.map((image, index) => (
                                    <Carousel.Item key={index}>
                                      <img
                                        src={image}
                                        className="img-fluid rounded w-100"
                                        alt={`menu ${index}`}
                                        onClick={() =>
                                          handleNavigation(item._id)
                                        }
                                      />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              ) : (
                                <img
                                  src={item.images[0]}
                                  className="img-fluid rounded w-100"
                                  alt="menu"
                                  onClick={() => handleNavigation(item._id)}
                                />
                              )}

                              <div className="d-flex justify-content-between">
                                <h3 className="text-black text-start px-2">{item.name.includes('(') ? item.name.split('(')[0] : item.name} <br />{item.name.includes('(') ? '(' + item.name.split('(')[1] : ''}</h3>
                                <div>

                                  {/* <p className="price-button text-black fs-4 ">
                                  ₹{item.amount}{" "}
                                </p> */}
                                  <del className=" text-danger stickout-price fs-6">₹ {item.offeramount}</del>
                                  <p className="price-button text-black fs-4 ">
                                    ₹{item.amount}{" "}

                                  </p>
                                </div>
                              </div>

                              {item.subname !== "" && <p className="fs-6 text-black fw-bold text-start px-2">{expandedDescriptions[item._id] ? item.subname : item.subname.slice(0, 90)} </p>}

                              {/* Show description with a "view more" option */}
                              {item.description && (
                                <div className="text-black fs-6 text-start px-2">
                                  {/* Show either truncated or full description based on state */}
                                  {
                                    expandedDescriptions[item._id]
                                      ? <span> {selectindex === 2 || selectindex === 3 ? renderDynamicValues(item.description) : item.description}</span>
                                      : <span> {item.description.slice(0, 70)} </span>
                                  }
                                  {item.description.length > 75 &&
                                    !expandedDescriptions[item._id] &&
                                    "..."}
                                  <div className="d-flex justify-content-center align-items-center">
                                    <p
                                      className="text-black border border-dark rounded mt-1 p-1 w-auto"
                                      onClick={() =>
                                        handleDescriptionToggle(item._id)
                                      }
                                    >
                                      {expandedDescriptions[item._id]
                                        ? "View Less"
                                        : "View More"}
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div
                                className="d-flex justify-content-around align-items-center"
                                style={{ cursor: "pointer" }}
                              >
                                {/* <p onClick={() => handleNavigation(item._id)} className="price-button text-black">

                                {" "}
                                ₹{item.amount}{" "}
                              </p> */}

                                <button
                                  onClick={() => onHandleOptions(item)}
                                  className="add-card-button"
                                >
                                  Add +
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* {selectindex === 1 || selectindex === 2 ? <div className="col-md-7 mb-3 " onClick={() => handleNavigation(item._id)}>
                            <div className="menu-box d-flex align-items-s card bg-theme-color p-4">

                              {item.subname !== "" && <p className="text-black">{item.subname}</p>}

                              <p className="text-black">{item.description}</p>
                            </div>

                          </div> : null} */}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* {selectindex !== 0 ? <div className="menu-content">

              <h3 className="theme-color">
                <span>90 Minutes - made to order from scratch</span>
              </h3>
              <h3 className="text-black">
                “Don't eat stale, when you can eat fresh”
              </h3>
            </div> : null} */}
          </div>
        </div>
      </section>
      <Footer />
      {/* <Modal
        visible={options}
        onCancel={() => {
          setOptions(false);
        }}
        height="300px"
        width="770px"
        style={{ marginTop: "3%" }}
        footer={null}
      >
        <h4 className="text-center font-weight-bold head-control mt-4 mb-5">
        Choose from our Classic,Epic or Gen-Z Biryani and we will pack it up with a box of Kababs.

        </h4>

        <div className="row">
          {optionslist &&
            optionslist.map((item) => (
              <div className="col-md-4" key={item._id}>
                <img
                  src={item.images[0]}
                  className="img-fluid image-hover"
                  alt="menu"
                  onClick={() => selectItem(productid,item._id)}
                />
                <div className="d-flex justify-content-between">
                  <p className="text-black fw-bold">{item.name.includes('(') ? item.name.split('(')[0] : item.name} <br/>{item.name.includes('(') ? '(' + item.name.split('(')[1] : ''}</p>
                </div>
              </div>
            ))}
        </div>
      </Modal> */}
      <Modal
        visible={options}
        onCancel={() => {
          setOptions(false);
        }}
        height="300px"
        width="770px"
        style={{ marginTop: "3%" }}
        footer={null}
      >
        <h4 className="text-center font-weight-bold head-control mt-4 mb-5">
          Try our Preminm yet affordable and must-explore different styles of Biryani Handcrafted by our Biryani Heros

        </h4>
        <div className="d-flex justify-content-center">
        <button className="button text-center">
        Click here below for Order Now
        </button>
        </div>
       
        <div className="d-flex justify-content-center text-center">
        {optionslist &&
            [{ "id": 1, 'name': "Swiggy ", uri: "https://www.swiggy.com/menu/880372?source=sharing", image: Zomoto }, { "id": 2, 'name': "Zomoto", uri: "https://zomato.onelink.me/xqzv/uux3v776", image: Swiggy }].map((item) => (
              <div className="col-md-4  mt-3 logo-images p-4" key={item._id}>
                <img
                  src={item.image}

                  alt="menu"
                  onClick={() => selectItem(productid, item.uri)}
                />
                
              </div>
            ))}
        </div>
        <h4 className="text-center font-weight-bold head-control mb-5 mt-3">
        
        Get upto ₹75 off on your first order
        </h4>
        <h5 className="text-center head-control mt-4 mb-5">
        "Also Try Our Family and Party Biryani Packs"
      

        </h5>
       
       
      </Modal>
    </>
  );
}
