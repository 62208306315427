import React, { useState, useEffect, useCallback } from "react";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { useDispatch, useSelector } from "react-redux";
import { GetAddCardProductById, AddOrderProductById, fetchStoreData, ApplyCouponFetch, ProfileUserData, AddAddressFetch } from "../reducer/thunks";
import constant from "../constant/constant";
import { Steps, Input, Form, message, Button, Select, Modal, Result, Typography } from "antd";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import { QRCode } from "react-qrcode-logo";
const { Paragraph, Text } = Typography;

// import io from "socket.io-client";
// import axios from "axios";
// const socket = io("http://localhost:5001",{
//   reconnectionDelay: 5000,
//   reconnection: true,
//   reconnectionAttemps: 10,
//   transports: ['websocket'],
//   agent: false,
//   upgrade: false,
//   rejectUnauthorized: false
// });

const { Option } = Select;

const IndianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Jammu and Kashmir",
];

const CitiesByState = {
  "Andhra Pradesh": ["Visakhapatnam", "Vijayawada", "Tirupati", "Guntur"],
  "Arunachal Pradesh": ["Itanagar", "Naharlagun", "Pasighat"],
  Assam: ["Guwahati", "Dibrugarh", "Jorhat", "Silchar"],
  Bihar: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  Chhattisgarh: ["Raipur", "Bhilai", "Bilaspur", "Durg"],
  Goa: ["Panaji", "Margao", "Vasco da Gama"],
  Gujarat: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  Haryana: ["Chandigarh", "Faridabad", "Gurgaon", "Rohtak"],
  "Himachal Pradesh": ["Shimla", "Manali", "Dharamshala", "Kullu"],
  Jharkhand: ["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro"],
  Karnataka: ["Bangalore", "Mysuru", "Hubballi", "Mangaluru"],
  Kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur"],
  "Madhya Pradesh": ["Bhopal", "Indore", "Jabalpur", "Gwalior"],
  Maharashtra: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  Manipur: ["Imphal", "Thoubal", "Bishnupur"],
  Meghalaya: ["Shillong", "Tura", "Jowai"],
  Mizoram: ["Aizawl", "Lunglei", "Champhai"],
  Nagaland: ["Kohima", "Dimapur", "Mokokchung"],
  Odisha: ["Bhubaneswar", "Cuttack", "Rourkela", "Berhampur"],
  Punjab: ["Chandigarh", "Ludhiana", "Amritsar", "Jalandhar"],
  Rajasthan: ["Jaipur", "Udaipur", "Jodhpur", "Kota"],
  Sikkim: ["Gangtok", "Namchi", "Mangan"],
  "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai", "Salem"],
  Telangana: ["Hyderabad", "Warangal", "Nizamabad", "Karimnagar"],
  Tripura: ["Agartala", "Udaipur", "Dharmanagar"],
  "Uttar Pradesh": ["Lucknow", "Kanpur", "Agra", "Varanasi"],
  Uttarakhand: ["Dehradun", "Haridwar", "Rishikesh", "Nainital"],
  "West Bengal": ["Kolkata", "Howrah", "Durgapur", "Asansol"],
  "Jammu and Kashmir": ["Srinagar", "Jammu", "Anantnag", "Baramulla"],
  // ... add more states and cities
};

const availablelocation = [
  { pincode: "560102", Address: "Hsr" },
  { pincode: "560034", Address: "koramangala" },
  { pincode: "560038", Address: "Indiranagar" },

]

const Payment = () => {
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const [form] = Form.useForm();
  const description = "This is a description.";
  const navigate = useNavigate();

  let userId = localStorage.getItem("userId");
  // Dummy cart items (replace with actual data)
  const [focus, SetFocus] = useState("");
  const [paynow, setpaynow] = useState(false);
  const [timer, setTimer] = useState(60);
  const [couponNumber, SetCoupon] = useState("");
  const [couponValue, SetCouponValue] = useState(0)
  const [couponType, SetCouponType] = useState(null)
  const [orderType, SetOrderType] = useState(false)
  const [orderfail, SetOrderfail] = useState(false)

  const [selectedState, setSelectedState] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState('');
  const API_KEY = 'AIzaSyBEtiMO9qnE-uXLcoQ6c2gZ0Uwe0pibQEk';
  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  const createOrder = async (params) => {
    // Implement your logic to create an order on the backend
    // and return the order details including an 'id'
    // Example:
    const response = await fetch(`${constant.baseUrl}api/order/orders`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  };

  const handlePaymentrazorpay = async () => {
    let params = {
      amount: Math.round(Math.round(calculateTotalAmount()) * 100),
      currency: "INR",
      receipt: "Receipt no. 1",
      notes: {
        notes_key_1: "Tea, Earl Grey, Hot",
        notes_key_2: "Tea, Earl Grey… decaf.",
      },
    };
    const order = await createOrder(params); //  Create order on your backend

    const options = {
      key: "rzp_live_ZTtFcdDX7OeqJJ", // Enter the Key ID generated from the Dashboard
      amount: Math.round(Math.round(calculateTotalAmount()) * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "LOCAL HEROS",
      description: "Test Transaction",
      image: "https://storage.googleapis.com/email-js-1a09b.appspot.com/localHeros/logo.65e819dc790bf567b08e.webp",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: async function (response) {
        console.log(response);
        const quantities = GetAddcardUserRes.AddCarts.map((item) => ({
          productId: item.productId, // Product ID
          quantity: item.quantity, // Quantity
          Options_product_Id: item.Options_item
        }));

        console.log(form.getFieldValue().addressId);

        let bodyresponce = {
          userId: localStorage.getItem("userId"), // Replace with a valid user ID
          addressId: AddAddressAPIRes?.address?._id ? AddAddressAPIRes?.address?._id : form.getFieldValue()?.addressId, // Replace with a valid address ID
          productIds: GetAddcardUserRes.AddCarts.map((item) => item.productId), // Replace with valid product IDs in an array
          totalAmount: calculateTotalAmount(), // Replace with the total amount for the order
          delivery: "Card",
          paymentStatus: "Confirmed",
          razorpay_payment_id: response.razorpay_payment_id,
          exta_add_item: "",
          exta_message: '',
          applycoupon: couponNumber,
          quantity: quantities, // Use the array of objects with product ID and quantity
        };

        await dispatch(AddOrderProductById(bodyresponce));
        SetOrderType(true)
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: getUserResponse.User.firstname,
        email: getUserResponse.User.email,
        contact: getUserResponse.User.mobilenumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#f56a00",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", async function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
      // let bodyresponce = {
      //   userId: localStorage.getItem("userId"), // Replace with a valid user ID
      //   addressId: form.getFieldValue().addressId, // Replace with a valid address ID
      //   productIds: GetAddcardUserRes.AddCarts.map((item) => item.productId), // Replace with valid product IDs in an array
      //   totalAmount: getTotal(), // Replace with the total amount for the order
      //   delivery: "Card",
      //   paymentStatus: "Confirmed",
      //   razorpay_payment_id: response.razorpay_payment_id,
      //   exta_add_item: "",
      //   exta_message: '',
      //   applycoupon: couponNumber
      // };

      // await dispatch(AddOrderProductById(bodyresponce));
      SetOrderfail(true)
      //   let bodyresponce = {
      //     userId: localStorage.getItem("userId"), // Replace with a valid user ID
      //     addressId: form.getFieldValue().addressId, // Replace with a valid address ID
      //     productIds: GetAddcardUserRes.AddCarts.map((item)=> item.productId), // Replace with valid product IDs in an array
      //     totalAmount: getTotal(), // Replace with the total amount for the order
      //     delivery: "Card",
      //     paymentStatus:"Failed",
      //     razorpay_payment_id: response.razorpay_payment_id,
      //   };

      // await  dispatch(AddOrderProductById(bodyresponce));
    });

    rzp1.open();
  };

  const handlePayment = async () => {
    let bodyresponce = {
      userId: localStorage.getItem("userId"), // Replace with a valid user ID
      addressId: form.getFieldValue().addressId, // Replace with a valid address ID
      productIds: GetAddcardUserRes.AddCarts.map((item) => item.productId), // Replace with valid product IDs in an array
      totalAmount: getTotal(), // Replace with the total amount for the order
      delivery: "Card",
      paymentStatus: "Processing",
      razorpay_payment_id: "1234",
      exta_add_item: "",
      exta_message: '',
      applycoupon: couponNumber
    };

    await dispatch(AddOrderProductById(bodyresponce));


    setpaynow(true);
  };



  const {
    GetAddcardUserRes,
    addloading: addloadingLoading,
    error: productListError,
  } = useSelector((state) => state.GetAddcardUserRes);
  const {
    storelist: getOrderResponse,
    addloading: addLoading,
    error: productError,
  } = useSelector((state) => state.storelist);
  console.log(getOrderResponse, "storelist");
  const {
    CouponRes: CouponResponse,
    addloading,
    error,
  } = useSelector((state) => state.CouponRes);
  console.log(CouponResponse, "CouponResponse");

  const {
    loading: getprofileUserLoading,
    loginerror: getprofileUserError,
    getprofile: getUserResponse,
  } = useSelector((state) => state.getprofile);


  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    phonenumber: "",
    country: "",
    streetAddress: "",
    townCity: "",
    state: "",
    pinCode: "",
  });

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress({
      ...shippingAddress,
      [field]: value,
    });
  };

  const validateRules = {
    required: { required: true, message: "This field is required" },
    email: { type: "email", message: "Please enter a valid email" },
    number: { type: "number", message: "Please enter a valid number" },
    zip: { type: "number", message: "Please enter a valid number" },
  };

  const onFinish = async (values) => {
    console.log(values);
    // Handle form submission here
    // handlePayment();
    const address = {
      city: "Bangalore",
      street: values.address,
      pinCode: values.pinCode,
      typeAddress: values.address,
      companyName: values.lastName,
      fullName: values.firstName,
      phone: values.phonenumber,
      email: values.email,
      state: "Karnataka",
      userId: localStorage.getItem('userId'),
    };
    console.log(address, "address");

    // Dispatch the AddAddressFetch action
    if (!form.getFieldValue().addressId) {
      await dispatch(AddAddressFetch(address));
    }
    console.log(form.getFieldValue().addressId);
    await handlePaymentrazorpay()
    // message.success("Form submitted successfully!");
  };

  const {
    loading: AddressUserLoading,
    loginerror: AddressUserError,
    otpVerificationResponse: AddAddressAPIRes,
  } = useSelector((state) => state.otpVerificationResponse);

  // Dummy payment details (replace with actual data)
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    // ... add more fields as needed
  });

  useEffect(() => {
    if (userId !== undefined || userId !== null) {
      dispatch(GetAddCardProductById(userId));
      dispatch(fetchStoreData(userId));
      dispatch(ProfileUserData(userId));

    }

  }, []);
  useEffect(() => {
    console.log(CouponResponse, "CouponResponse");

    if (CouponResponse?.success) {
      message.success("Coupon applied successfully");

      const { bodysend } = CouponResponse;

      if (bodysend) {
        const { coupon_type, discount } = bodysend;

        SetCouponValue(discount);
        SetCouponType(coupon_type);
      } else {
        // Handle case where bodysend is undefined or null
        console.error("Invalid coupon response: bodysend is missing");
      }
    } else if (typeof CouponResponse === 'string') {
      message.error(CouponResponse);
    } else {
      // Handle other types of CouponResponse that are not valid
      console.error("Invalid CouponResponse:", CouponResponse);
    }
  }, [CouponResponse]);

  useEffect(() => {
    if (userId !== undefined || userId !== null) {
      dispatch(GetAddCardProductById(userId));
      dispatch(fetchStoreData(userId));

    }
  }, []);
  useEffect(() => {
    if (paynow) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      // Clear the interval and close the modal when the timer reaches zero
      return () => {
        clearInterval(interval);
        if (timer === 0) {
          setpaynow(false);
          setTimer(60);

          // Optionally, you can perform additional actions when the timer reaches zero
          // For example, dispatch an action, show a message, etc.
        }
      };
    }
  }, [paynow, timer]);

  // useEffect(() => {
  //   axios.get('http://localhost:5000/api/order/payment-listen')
  //   .then(response => {
  //     // Handle payment success
  //     // setPaymentStatus(response.data.success);
  //     console.log(response,"response");
  //   })
  //   .catch(error => {
  //     // Handle payment failure
  //     console.error(error);
  //   });
  // }, []);





  const handlePayNow = () => {
    form.submit((values) => {
      // You can access the form values here and perform any additional actions
      console.log("Form values submitted:", values);

      // You can perform additional actions after form submission if needed
    });
  };

  const currentloacation = () =>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          getAddressFromCoordinates(position.coords.latitude,position.coords.longitude)
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  const handleMapReady = (lat, lng) => {
    axios({
      method: "get",
      url: `https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng}&key=${API_KEY}`,
      responseType: "stream",
    }).then((responseJson) => {
      let city,
        country,
        zipcode,
        streetNumber,
        route,
        premise,
        sublocality,
        regionName;
      responseJson?.results?.[0]?.address_components.map(
        (address, index) => {
          address.types.map((types, index) => {
            if (types === "locality") {
              city = address.long_name;
            } else if (types === "country") {
              country = address.long_name;
            } else if (types === "postal_code") {
              zipcode = address.long_name;
            } else if (types === "street_number") {
              streetNumber = address.long_name;
            } else if (types === "route") {
              route = address.long_name;
            } else if (types === "premise") {
              premise = address.long_name;
            } else if (types === "sublocality") {
              sublocality = address.long_name;
            } else if (types === "administrative_area_level_1") {
              regionName = address.long_name;
            }
          });
        }
      );
      if (responseJson.status === "OK") {
        console.log({
          address: responseJson?.results?.[0]?.formatted_address,
        });
        form.setFieldsValue({
          address: responseJson?.results?.[0]?.formatted_address,
          // Add other fields as needed
        });
        // this.setState({
        //   Address: responseJson?.results?.[0]?.formatted_address,
        //   city: city,
        //   zipcode: zipcode,
        //   StreetName: route,
        // });
      } else {

      }
    })
    .catch((error) => {
    });
  };

 const getAddressFromCoordinates = (latitude, longitude) => {
    
    return new Promise((resolve, reject) => {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          latitude +
          "," +
          longitude +
          "&key=" +
          API_KEY
      )
        .then((response) => response.json())
        .then((responseJson) => {
          let city,
            country,
            zipcode,
            streetNumber,
            route,
            premise,
            sublocality,
            regionName;
          responseJson?.results?.[0]?.address_components.map(
            (address, index) => {
              address.types.map((types, index) => {
                if (types === "locality") {
                  city = address.long_name;
                } else if (types === "country") {
                  country = address.long_name;
                } else if (types === "postal_code") {
                  zipcode = address.long_name;
                } else if (types === "street_number") {
                  streetNumber = address.long_name;
                } else if (types === "route") {
                  route = address.long_name;
                } else if (types === "premise") {
                  premise = address.long_name;
                } else if (types === "sublocality") {
                  sublocality = address.long_name;
                } else if (types === "administrative_area_level_1") {
                  regionName = address.long_name;
                }
              });
            }
          );
          if (responseJson.status === "OK") {
           console.log(responseJson?.results,"responseJson?.results");
           form.setFieldsValue({
            address: responseJson?.results?.[0]?.formatted_address,
            // Add other fields as needed
          });
            resolve(responseJson?.results?.[0]?.formatted_address);
          } else {
            reject("not found");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  const handlePaywithpay = () => {
    const paymentUrl = `upi://pay?pa=MAB.037346029690018@axisbank&pn=Local Heroes Biriyaari&tn=Local Heroes Biriyaari&am=${calculateTotalAmount()}`;
    window.location.href = paymentUrl; // Redirect to payment URL
  };
  // Define the getSubtotal function
  function getSubtitle() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.map((item) => (
        <div className="cart-totals-item d-flex justify-content-start">
          <span>
            <img
              src={`${item?.product?.images[0]}`}
              alt={`Product Image ${item.product.name}`}
              style={{ width: "80px", height: "80px" }}
            />
          </span>
          <span className="p-2 text-start">
            {item?.product?.name} <br />
            {item?.quantity} x ₹{item?.product?.amount}
          </span>
        </div>
      ))
    );
  }
  const handleStateSelect = (value) => {
    // Handle the selected state, e.g., update the state in the component
    console.log("Selected State:", value);

    // Use filter to find the selected address
    const filterAddress = getOrderResponse.Addresslist.find((state) => state._id === value);

    // Log the filtered address
    console.log("Filtered Address:", filterAddress);

    // Use setFieldsValue to update the form values
    form.setFieldsValue({
      addressId: filterAddress._id,
      firstName: filterAddress.fullName,
      lastName: filterAddress.fullName,
      phonenumber: filterAddress.phone,
      address: filterAddress.typeAddress,
      email: filterAddress.email,
      pinCode: filterAddress.pinCode
      // Add other fields as needed
    });

  };



  // Define the getTotal function
  function getTotal() {
    return (
      GetAddcardUserRes?.AddCarts &&
      GetAddcardUserRes.AddCarts.reduce(
        (total, item) => total + item.product.amount * item.quantity,
        0
      )
    );
  }

  const handleCoupon = () => {
    let bodypass = {
      couponCode: couponNumber,
      userId: userId
    }
    dispatch(ApplyCouponFetch(bodypass))
  }
  const calculateDiscount = () => {
    let discount = 0;
    if (couponType === 'amount') {
      discount = Number(couponValue);
    } else if (couponType === 'percentage') {
      discount = (Number(couponValue) / 100) * (getTotal());
    } else if (couponType === 'bogo') {
      // Example BOGO calculation (adjust as per your requirements)
      discount = getTotal() / 2; // Assuming buy one get one free
    }
    return discount.toFixed(2);
  };

  const renderDiscount = () => {
    const discountAmount = calculateDiscount();
    return <span>{`-${discountAmount}`}</span>;
  };

  const calculateTotalAmount = () => {

    let discount = 0;
    if (couponType) {
      if (couponType === 'amount') {
        discount = Number(couponValue);
      } else if (couponType === 'percentage') {
        discount = (Number(couponValue) / 100) * (getTotal());
      } else if (couponType === 'bogo') {
        // Example BOGO calculation (adjust as per your requirements)
        discount = getTotal() / 2; // Assuming buy one get one free
      }
    }

    // Calculate the total amount after discount
    const totalAmount = (450 > +getTotal() ? getTotal() + 50 : getTotal()) - discount;
    return totalAmount.toFixed(2);
  };

  const shippingamount = () => {
    const calculatedAmount = parseFloat(getTotal()).toFixed(0);

    if (450 > +calculatedAmount) {
      return `${calculatedAmount} +(DC) 50 =` + " " + Number(+calculatedAmount + 50);
    } else {
      return "Delivery Fee";
    }
  };




  const renderCardtotals = () => {

    return (
      <div className="col-md-4 h-25 mt-3 price-card">
        <div className="cart-totals p-4">
          {getSubtitle()}
          <div className="cart-totals-item border-top mt-3 pt-3 align-self-center">
            <span className="m-2">Coupon</span>
            <Form.Item
              name="firstName"
              className="col-md-6"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.firstName}
                className="form-control text-input-address"
                onChange={(e) =>
                  SetCoupon(e.target.value)
                }
              />
            </Form.Item>
            <button className="btn  text-white bg-theme-color text-center h-25" onClick={handleCoupon}>
              Apply
            </button>
          </div>
          <div className="cart-totals-item border-top mt-3 pt-3">
            <span>Sub-total</span>
            <span>{getTotal()}</span>
          </div>
          <div className="cart-totals-item">
            <span>Shipping</span>
            <span> {shippingamount()}</span>
          </div>
          <div className="cart-totals-item">
            <span>GST Tax</span>
            {/* <span>18% ({getTotal()} * 0.18) = {(getTotal() * 0.18).toFixed(2)}</span> */}
            <span>0% </span>

          </div>
          <div className="cart-totals-item">
            <span>Discount</span>
            {renderDiscount()}
          </div>

          <div className="cart-totals-item total border-top mt-4 pt-3">
            <span>Total</span>
            <div className="">
              <div>
                {couponType && <del className="stickout-price mx-3 red-code">
                  ₹{(getTotal()).toFixed(2)}
                </del>}

                <span>
                  ₹{calculateTotalAmount()}
                </span>
              </div>
            </div>




          </div>
          <div className="cart-totals-item-btn mt-4 mb-4">
            <button className="btn button " onClick={handlePayNow}>
              Proceed to Payment
            </button>
          </div>
        </div>
        {/* {renderPaymentForm()} */}
      </div>
    );
  };

  const renderShippingAddressForm = () => {
    return (
      <div className="shipping-address col-md-8 mt-3 h-25 price-card">
        <h3 className="shipping-header">Billing Information</h3>

        <Form form={form} name="shippingAddressForm" onFinish={onFinish}>
          <div className="col-md-12 row">
            <div className="col-md-12">
              {getOrderResponse && <Form.Item
                name="addressId"
                label="Select an Address"
                labelCol={{ span: 24 }}
                className="col-md-6"
              // rules={[validateRules.required]}
              >
                <Select
                  placeholder="Select an Address"
                  onChange={(selectedState) => handleStateSelect(selectedState)}
                >
                  {getOrderResponse && getOrderResponse?.Addresslist && getOrderResponse?.Addresslist.map((state) => (
                    <Option key={state._id} value={state._id}>
                      {state.typeAddress}
                    </Option>
                  ))}
                </Select>
              </Form.Item>}
            </div>



            <Form.Item
              name="firstName"
              label="First Name"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.firstName}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("firstName", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Last Name"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.lastName}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("lastName", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              name="phonenumber"
              label="Phone Number"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[
                validateRules.required,
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please enter a valid phone number",
                },
              ]}
            >
              <Input
                value={shippingAddress.phonenumber}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("phonenumber", e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-12 row"> 
          <Form.Item
            name="address"
            label="Address"
            className="col-md-11"
            labelCol={{ span: 24 }}
            rules={[validateRules.required]}
          >
            <Input
              value={shippingAddress.address}
              className="form-control text-input-address"
              onChange={(e) =>
                handleShippingAddressChange("address", e.target.value)
              }
            />
          </Form.Item>
            <div className="d-flex col-md-1 mt-4" style={{ cursor: "pointer" }} onClick={currentloacation}>
              <img
                src={require("../assets/images/location-home.png")}
                className="logo-wid-location mt-3"
                alt="Logo"
              />
              <div>
               
                <p className="text-white location-text">
                  {/* {pincodeId ? pincodeId : 'Location'} */}

                </p>
              </div>

            </div>
          </div>
         

          {/* <div className="col-md-12 row">
            <Form.Item
              label="Country"
              name="country"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Select
              // defaultValue="India"
              >
                <Option value="India">India</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Select
                value={selectedState}
                onChange={handleStateChange}
                placeholder="Select a state"
              >
                {IndianStates.map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              className="col-md-4"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Select placeholder="Select a city" disabled={!selectedState}>
                {selectedState &&
                  CitiesByState[selectedState].map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div> */}

          <div className="col-md-12 row">
            <Form.Item
              name="email"
              label="Email"
              className="col-md-8"
              labelCol={{ span: 24 }}
              rules={[validateRules.required]}
            >
              <Input
                value={shippingAddress.email}
                className="form-control text-input-address"
                onChange={(e) =>
                  handleShippingAddressChange("email", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              name="pinCode"
              label="ZIP Code"
              className="col-md-4"
              labelCol={{ span: 24 }}
            // Uncomment the rules below if you want to add validation rules
            // rules={[validateRules.required, validateRules.zip]}
            >
              <Select
                value={shippingAddress.pinCode} // bind to shippingAddress.pinCode
                onChange={(value) => handleShippingAddressChange("pinCode", value)}
              >
                {availablelocation.map((location) => (
                  <Option key={location.pincode} value={location.pincode}>
                    {location.Address}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <>
      <Header />

      <section className="py-5 mt-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="section-heading">
                <h3 className="introducing theme-bg-text ">Checkout</h3>
              </div>
            </div>
          </div>

          <div className="">
            {/* <div className="col-md-12">
              <Steps
                current={2}
                items={[
                  {
                    title: "Address",
                    description,
                  },
                  {
                    title: "Order Summary",
                    description,
                    subTitle: "Left 00:00:08",
                  },
                  {
                    title: "payment",
                    description,
                  },
                ]}
              />
            </div> */}
            <div className="col-md-12 row mt-5">
              {renderShippingAddressForm()}
              {renderCardtotals()}
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Modal
        visible={paynow}
        onCancel={() => {
          setpaynow(false);
        }}
        width="270px"
        height="300px"
        style={{ marginTop: "3%" }}
        footer={null}
      >
        <h4 className="text-center font-weight-bold head-control mt-4">
          Scan To Pay
        </h4>
        <p className="text-center p-text">Choose one of the options to go</p>
        <p className="text-center">Timer 00:{timer}</p>
        <div>
          <br />
          <QRCode
            value={`upi://pay?pa=MAB.037346029690018@axisbank&pn=Local Heroes Biriyaari&tn=Local Heroes Biriyaari&am=${calculateTotalAmount()}`}
            size="200"
            // logoImage={require("../assets/images/gpaylogo.jpg")}
            logoWidth="80"
            logoHeight="100"
            logoOpacity="1"
            logoPaddingStyle=""
          />
          <p>Scan the code using PhonePe, Google Pay or Paytm</p>
          <div className="text-center">
            <button className="btn button " onClick={handlePaywithpay}>
              Pay with UPI Pay
            </button>
          </div>

        </div>
      </Modal>
      <Modal
        visible={orderType}
        onCancel={() => {
          setpaynow(false);
        }}
        width="770px"
        height="300px"
        style={{ marginTop: "2%" }}
        footer={null}
      >
        <Result
          status="success"
          title="Order Confirmed"
          subTitle="Your order has been successfully confirmed. Order number: 2017182818828182881. Preparing your delicious meal, server takes 10-15 minutes, please wait patiently."
          extra={[
            <Button onClick={() => {
              SetOrderType(false)

              navigate(`/account`);

            }} type="primary" key="track">
              Track Order
            </Button>,
            <Button key="newOrder" onClick={() => {
              SetOrderType(false)
              navigate(`/menu`);

            }}>
              Place New Order
            </Button>,
          ]}
        />

      </Modal>
      <Modal
        visible={orderfail}
        onCancel={() => {
          SetOrderfail(false);
        }}
        width="770px"
        height="300px"
        style={{ marginTop: "2%" }}
        footer={null}
      >
        <Result
          status="error"
          title="Order Failed"
          subTitle="We're sorry, but there was an issue processing your order. Please review the following information and try again."
          extra={[
            <Button onClick={() => {
              SetOrderfail(false);

            }} type="primary" key="retry">
              Retry Order
            </Button>,
            <Button onClick={() => {
              SetOrderfail(false);
            }} type key="contact">
              Contact Support
            </Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Unfortunately, your order could not be processed due to the following reasons:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" /> Your payment method was declined. Please check your payment details and try again.
            </Paragraph>

          </div>
        </Result>


      </Modal>
    </>
  );
};

export default Payment;
