import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import { fetchBannerData, fetchProductData } from "../reducer/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import constant from "../constant/constant";
import { TypeAnimation } from "react-type-animation";
import SplashScreen from "../componets/SplashScreen";
import Banners from "../componets/Banners";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [product, setProduct] = useState([]);
  const [selectindex, setSelectIndex] = useState(0);
  const myElementRef = useRef(null); // Define a ref using useRef hook

  document.title = "Local Heros";
  document.getElementsByTagName("META")[2].content = "Local Heros";
  // Destructuring with different names to avoid conflict
  const {
    data,
    loading: bannerLoading,
    error: bannerError,
  } = useSelector((state) => state.data);
  const {
    productlist,
    loading: productListLoading,
    error: productListError,
  } = useSelector((state) => state.productlist);



  const handleNavigation = (productId) => {
    // Navigate to the specified product id
    navigate(`/product/${productId}`);
  };
  useEffect(() => {
    dispatch(fetchBannerData());
    dispatch(fetchProductData());
  }, []);
  useEffect(() => {
    if (
      productlist?.userProducts &&
      data?.Categorys &&
      data?.Categorys.length > 0
    ) {
      changeMenu(data.Categorys[0]._id, 0); // Assuming you want to pass the ID of the first category
    }
  }, [data?.Categorys]); 

  useEffect(() => {
    const handleSticky = () => {
      const stickyElements = document.querySelectorAll(".sticky-logo-1");

      stickyElements.forEach((element) => {
        const offset = element.offsetTop;

        if (window.pageYOffset > offset) {
          element.classList.add("sticky");
        } else {
          element.classList.remove("sticky");
        }
      });
    };

    window.addEventListener("scroll", handleSticky);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleSticky);

    };
  }, []);

  const handleClick = (id, menuItem) => {
    // Check if the menu item matches the desired value
    // Accessing the element by its ID and performing actions
    const myElement = document.getElementById(id);
    if (myElement) {
      // Do something with the element, for example, scroll to it
      myElement.scrollIntoView({ behavior: 'smooth' });
    }

  };

  const changeMenu = (id, index) => {
    setSelectIndex(index)
    // Filter the user products based on the provided id
    const filteredProducts = productlist.userProducts.filter((products) => {
      return products.category === id;
    });

    console.log("Filtered Products:", filteredProducts);

    // Update the state with the filtered products
    setProduct([...filteredProducts]); // Using spread operator to create a new array

    console.log("Updated Product State:", product);

    // You can also directly return the filtered products if needed
    return filteredProducts;
  };



  return data ? (
    <>
      <Helmet>
        <title>Local Heroes</title>
        <meta
          name="description"
          content="This is a description of your page."
        />
        <meta name="keywords" content="local heroes, community, volunteer" />
      </Helmet>

      <Header onTabclick={handleClick} />
      <div id="myElement5" ref={myElementRef}>
      <Banners />

      </div>
      <section className="py-5 why-bryani">
        <div className="container">
          <h2 className="mb-3 mt-2 ">Why Is Biryani a Celebration?</h2>
          <p>
            Biryani is an emotion that evokes joy and festivities. It is not just a dish but a cultural icon, symbolizing the richness of our culinary heritage, from its aromatic spices to its succulent meat. Every bite of Biryani is a journey through tradition and flavour, that's why Biryani is genuinely the most celebrated dish in our country.<br />

          </p>

          <h2 className="mb-3 mt-5 ">Origin of Biryani</h2>
          <p>
            There are many versions as to how Biryani originated. We want to tell you an untold version. There are records of a rice dish known as Oon Soru in Tamil literature as early as the year 2 A.D. This ancient concoction was said to be made of rice, ghee, meat, turmeric, coriander, pepper, and bay leaf and was used to feed military warriors.
          </p>
        </div>
      </section>



      <section className='bg22 py-5' id="myElement" ref={myElementRef}>

        <div className="">
          <h2 className="introducing">Introducing </h2>
          {/* <h2 className="introducing"><span className="theme-color" style={{ color: '#e57228' }}>The Different Varieties of Biryani </span></h2> */}
        </div>
        <img src={require("../assets/images/logos.webp")} className='mid-logo d-block mx-auto pb-1 pt-2' />
        <p className="bg-poss text-center">
          “Local Heros” is a tech-enabled speciality food company <br />

          We handcraft premium yet affordable & a must explore different styles of Biryani
          <br />

          Our yummilicious Biryani will nourish your body and cherish your mind.
          <br />

          Our Biryani will be new to your taste buds because of meticulously measured ingredients, <br />with a concoction of not more than 14 different Indian spices
          (our secret masala),<br /> premium ingredients, a practiced technique, and the dum pukht method.



        </p>
        <p className="title-lite mt-5 bg-orange d-flex justify-content-center">
          <p className=" text-white p-2 rounded text-center pt-3" >   At Local Heros we thrive to make your Biryani lite on your tummy & your wallet. </p>
        </p>
      </section>

      <section className="bg-4 py-5" id="myElement1" ref={myElementRef}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 theme-color text-center">
              <h2>USP's</h2>
              <p className="mt-3">
                If you don't experience bloating, heartburn, thirst, or exhaustion after eating Biryani, then it's a good Biryani.


              </p>
            </div>
          </div>
          <div className="row mt-4 justify-content-center d-none">
            <div className="col-md-2 col-6 mb-4 mb-md-0">
              <div className="bg-theme-color ups-icon">
                <img
                  src={require("../assets/images/ups-icon1.png")}
                  className=""
                  alt="icon"
                />
                <p className="mb-0">Hygiene Standards</p>
              </div>
            </div>
            <div className="col-md-2 col-6 mb-4 mb-md-0">
              <div className="bg-theme-color ups-icon">
                <img
                  src={require("../assets/images/ups-icon2.png")}
                  className=""
                  alt="icon"
                />
                <p className="mb-0">Quality Ingredients</p>
              </div>
            </div>
            <div className="col-md-2 col-6 mb-4 mb-md-0">
              <div className="bg-theme-color ups-icon">
                <img
                  src={require("../assets/images/ups-icon3.png")}
                  className=""
                  alt="icon"
                />
                <p className="mb-0">Flavorful Varieties</p>
              </div>
            </div>
            <div className="col-md-2 col-6 mb-4 mb-md-0">
              <div className="bg-theme-color ups-icon">
                <img
                  src={require("../assets/images/ups-icon4.png")}
                  className=""
                  alt="icon"
                />
                <p className="mb-0">Customer Satisfaction</p>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="bg-theme-color ups-icon">
                <img
                  src={require("../assets/images/ups-icon5.png")}
                  className=""
                  alt="icon"
                />
                <p className="mb-0">Commitment to Service</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-body-secondary vh-75">
        <div className="container " style={{ minHeight: "450px" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className='text-dark'>Our Biryani is Lite on your Tummy</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 flip-card-sec pt-lg-5 pt-0">

                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/rice.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Aged Rice</p>

                    </div>
                    {/* style="width:300px;height:300px;" */}
                    <div className="flip-card-back">
                      <p>We use 18-month-old Jeera Samba rice from Bardhaman, West Bengal. It has a low Glycemic Index and does not cause a spike in blood sugar levels.</p>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/pressed-oil.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Cold Pressed Oil </p>

                    </div>
                    <div className="flip-card-back">
                      <p>We are the first Biryani company to use cold-pressed groundnut oil, so you can indulge guilt-free without worrying about extra calories or clogged arteries.</p>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/small-farm (1).jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Chicken From Small Farms</p>

                    </div>
                    <div className="flip-card-back">
                      <p>We source our chicken directly from small farms, ensuring no antibiotics or steroids are used.</p>
                    </div>
                  </div>
                </div>




              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/organic-vegetable.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Organic Vegetables</p>

                    </div>
                    <div className="flip-card-back">
                      <p>We are the first Biryani company to use only certified organic vegetables, including green leafy vegetables like methi, palak, coriander, and mint, making your Biryani pesticide-free.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/secret-masala.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Secret Masalas</p>

                    </div>
                    <div className="flip-card-back">
                      <p>We use a secret blend of 14 freshly pounded spices to create our Biryani masala.
                      </p>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/purifired-water.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Purified Water</p>

                    </div>
                    <div className="flip-card-back">
                      <p>We use only purified water for cooking your Biryani( NOTE: A lot of water is used to make a batch of biryaani)
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">

                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/hotsupplychain.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Hot Supply  Chain</p>

                    </div>
                    <div className="flip-card-back">
                      <p>After cooking food, especially rice, for 2 hours, a type of bacteria called Bacillus Cereus can form. This bacteria is harmful to your digestive system. To prevent this bacterial growth, we maintain the quality of your Biryani from when it is cooked until it is served through a seamless hot supply chain management system at a temperature of 63 degrees.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/Hygienic Kitchen.jpeg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">Hygienic Kitchen  </p>

                    </div>
                    <div className="flip-card-back">
                      <p>We use a state-of-the-art kitchen to ensure that bare hands do not touch your food throughout making and delivering your Biryani. Our hygiene is non-negotiable; all our Biryani Heroes(Chefs) are well trained and maintain personal hygiene, such as masks, head caps, bathing, and no smoking, alcohol, or tobacco.
                      </p>
                    </div>
                  </div>
                </div>



              </div>
              <div className="col-lg-4 flip-card-sec pt-5 pb-3 ">


                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/artificial-color.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">No Tastemakers & colours </p>

                    </div>
                    <div className="flip-card-back">
                      <p>We refrain from using artificial colours, preservatives, and taste enhancers to enhance the flavour and appeal of our Biryani.

                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-body-secondary vh-75">
        <div className="container " style={{ minHeight: "450px" }}>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2 className='text-dark'>Our Biryani is Lite on your Wallet</h2>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 pt-lg-5 pt-0">

                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/noresturent.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">No Restaurant </p>

                    </div>
                    {/* style="width:300px;height:300px;" */}
                    <div className="flip-card-back">
                      <p>We do not serve our Biryani through a restaurant model because the real estate cost is obnoxiously high and we don’t want to add that cost in your Biryani.</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 pt-5">

                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={require("../assets/images/noaditionalmanepower.jpg")} className="rot-img1 d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">No Additional Manpower </p>

                    </div>
                    {/* style="width:300px;height:300px;" */}
                    <div className="flip-card-back">
                      <p>In a restaurant modal there are lot of people involved in serving a Biryani to you right from a manager to a server to a cashier to a security guard. we have removed all of them to make your Biryani lite on your wallet.</p>
                    </div>
                  </div>
                </div>


              </div>
             

            </div>
          </div>
        </div>
      </section>
    
      <section className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center mb-4">
              <h2>Blogs</h2>
            </div>


            {data &&
              data.Faqs &&
              data.Faqs.slice(0, 3).map((item) => (
                <div className="col-md-4 text-center mb-5 mb-md-0">
                  <div className="blog-box mb-4">
                    <img
                      src={`${item.imageUrl}`}
                      className="img-fluid mb-3"
                    />
                    <div className="p-2">
                      <h3 className="font-size-blogs blogspace">{item.name}</h3>
                      <p className="pb-3 blogspace mt-3">
                        {item.description}{" "}
                      </p>
                    </div>

                  </div>
                  <Link to={`/blog/${item._id}`} className="readmore-button">
                    {" "}
                    Read more{" "}
                  </Link>
                </div>
              ))}

            {/* {data &&
              data.Faqs &&
              data.Faqs.slice(0, 3).map((item) => (
                <div className="col-md-4 text-center mb-5 mb-md-0">
                  <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">

                      <img src={`${item.imageUrl}`} className="rot-img-blog d-block mx-auto broder-radio-img" alt="" />
                      <p className="title-lite">{item.name}</p>

                    </div>
                    <div className="flip-card-back">
                      <p>{item.description}{" "}</p>
                    </div>
                  </div>
                </div>
                </div>
              ))} */}


          </div>
        </div>
      </section>
      <div>
      <Footer onTabclick={handleClick}/>

      </div>
    </>
  ) : <SplashScreen />
}